import React, { FC } from 'react';

import MilkLogo from 'src/images/svg/code-example/milk-logo.svg';

const Footer: FC = () => {
  return (
    <footer className='footer container'>
      <div className='developed-by'>
        Developed by{' '}
        <a rel='noreferrer' target='_blank' href='https://milkandcartoons.com'>
          Milk & Cartoons
        </a>
      </div>
      <a rel='noreferrer' target='_blank' href='https://milkandcartoons.com'>
        <img src={MilkLogo} alt='Milk&Cartoons' />
      </a>
    </footer>
  );
};

export default Footer;
