import React, { FC } from 'react';

const Header: FC = () => {
  return (
    <header className='header container'>
      <div className='logo'>
        <span>endpoint</span>
        <div className='ellipse' />
        <span>space</span>
      </div>
      <div className='sign-up'>
        <a
          href={`${process.env.DASHBOARD_URL}`}
          target='_blank'
          className='sign-up-btn gradient-button'
          rel='noopener noreferrer'
        >
          <span>Dashboard</span>
        </a>
      </div>
    </header>
  );
};

export default Header;
