import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Prism from 'prismjs';

import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import EmailForm from 'components/pages/home/EmailForm';

import { Endpoint, Maybe, useRecordsQuery } from 'src/generated/graphql';

import ArrowOne from 'images/svg/code-example/arrow-1.inline.svg';
import ArrowSecond from 'images/svg/code-example/arrow-2.inline.svg';
import ArrowThird from 'images/svg/code-example/arrow-3.inline.svg';
import Copy from 'images/svg/code-example/copy.inline.svg';
import UnderlineIcon from 'images/svg/code-example/underline.inline.svg';
import Spinner from 'images/svg/code-example/spinner.svg';

const code = `<form
  action=
  method="POST"
>
  <input
    type="text"
    name="name"
    placeholder="Full name"
  />
  <button type="submit">
    Send
  </button>
</form>`;

interface Props {
  endpoint?: Maybe<Endpoint>;
  endpointError: boolean;
  setEndpointError: React.Dispatch<React.SetStateAction<boolean>>;
}

const copyToClipboard = async (text: string): Promise<void> => {
  return navigator.clipboard.writeText(text);
};

const CodeExample: FC<Props> = ({ endpoint, endpointError, setEndpointError }) => {
  const [isActive, setIsActive] = useState(false);

  const isCalled = typeof window !== 'undefined' ? window.localStorage.getItem('called') : null;

  const [called, setIsCalled] = useState<boolean>(isCalled === 'true');

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const { data } = useRecordsQuery({
    variables: { where: { endpointId: endpoint?.id as string } },
    skip: !endpoint?.id || !called,
    pollInterval: 5000,
  });

  const record = useMemo(() => data?.records?.[0], [data?.records]);

  const recordDate = useMemo(() => {
    const hours = new Date(record?.createdAt).getHours();
    const minutes = new Date(record?.createdAt).getMinutes();

    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  }, [record?.createdAt]);

  const endpointURL = `${process.env.DATA_API_URL}/${endpoint?.id || '...'}`;

  const handleClick = () => {
    if (isActive) setIsActive(false);
    else {
      setIsActive(true);
      setTimeout(() => setIsActive(false), 3000);
    }

    copyToClipboard(endpointURL);
  };

  // eslint-disable-next-line consistent-return
  const handleSendSubmission = useCallback(async () => {
    if (!endpoint?.id) return null;

    localStorage.setItem('called', 'true');
    setIsCalled(true);

    try {
      const res = await fetch(endpointURL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          name: 'Endpoint',
        }),
      });

      if (res.status === 400) throw new Error();
    } catch {
      setIsCalled(false);
      localStorage.setItem('called', 'false');

      setEndpointError(true);
    }
  }, [endpointURL, endpoint?.id, setEndpointError]);

  return (
    <section className='container'>
      <div className='code-example-section'>
        <div className='stage first-stage'>
          <span className='stage-number'>1.</span>
          <span className='stage-description'>
            <span>This is your endpoint link.</span>
            <span>Copy and paste it into your website.</span>
            <ArrowOne />
          </span>
        </div>
        <div className='code-example-container'>
          <div className='code-example'>
            <pre>
              <code className='language-html'>{code}</code>
            </pre>
            <button type='button' className='copy-endpoint-btn' onClick={handleClick}>
              <div className={`notification${isActive ? ' active' : ''}`}>Copied</div>
              <span className='endpoint-id'>{endpointURL}</span>
              <Copy />
            </button>
          </div>
          <div className='submission-section'>
            <div className='stage get-result-stage'>
              <span className='stage-description'>
                Get the result
                <ArrowSecond />
              </span>
            </div>
            <div className='submission'>
              <span className='submission-label'>Form submissions:</span>
              {endpointError && (
                <span className='new-submission'>
                  <span>Some error occurred, recreating endpoint...</span>
                </span>
              )}
              {record && (
                <span className='new-submission'>
                  <span>New submission from </span>
                  <span>{getUnicodeFlagIcon(record?.country || 'US')}</span>
                  <span> at {recordDate}</span>
                </span>
              )}
              {!record && !called && (
                <button
                  type='button'
                  onClick={handleSendSubmission}
                  className='gradient-button submission-btn'
                >
                  <span>Send a test submission</span>
                </button>
              )}
              {!record && called && (
                <div className='submission-spinner-container'>
                  <img className='submission-spinner' src={Spinner} alt='loading...' />
                </div>
              )}
            </div>
            <div className='stage get-result-stage'>
              <span className='stage-description'>
                Get the result
                <ArrowSecond />
              </span>
            </div>
          </div>
        </div>
        <div className='email-input-container'>
          <div className='stage second-stage'>
            <span className='stage-number'>2.</span>
            <span className='stage-description'>
              Enter your email to view submissions and save your endpoint.
              <ArrowThird />
            </span>
          </div>
          <EmailForm endpointId={endpoint?.id} />
          <div className='stage third-stage'>
            <span className='stage-number'>3.</span>
            <span className='stage-description'>
              Check your email
              <UnderlineIcon />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CodeExample;
