/* eslint-disable no-useless-escape */
import React, { FC, useCallback, useState } from 'react';

interface Props {
  endpointId?: string;
}

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailForm: FC<Props> = ({ endpointId }) => {
  const [email, setEmail] = useState<string>('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email && email.match(emailRegExp)) {
      window.open(`${process.env.DASHBOARD_URL}/login/${email}/${endpointId}`);
    }
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  return (
    <div className='input-container'>
      <form onSubmit={handleSubmit}>
        <input onChange={handleChange} type='email' placeholder='Email' required />
        <button type='submit' className='gradient-button send-email-btn'>
          <span>Save Endpoint</span>
        </button>
      </form>
    </div>
  );
};

export default EmailForm;
